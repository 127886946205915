<template>
	<Dialog :dialog="updatedDialog" :dialog-width="900" @close="$emit('close')">
		<template #title>Update SSL </template>
		<template #body>
			<v-sheet id="lead">
				<v-form
					ref="extendCheckoutForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="">
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label required">Service Provider</label>
							</v-col>
							<v-col lg="9" class="my-auto py-0 position-relative">
								<v-autocomplete
									:items="serverProviders"
									item-text="name"
									item-value="name"
									hide-details
									outlined
									placeholder="Service Provider"
									v-model="ssl.service_provider"
									:class="{
										required: !ssl.service_provider,
									}"
									:menu-props="{ contentClass: 'rounded-lg' }"
									:rules="[vrules.required(ssl.service_provider, 'Service Provider')]"
								>
									<template #selection="{ item }">
										<div class="fw-600 text-uppercase">{{ item.name }}</div>
										<div v-if="item.website" class="ml-2 fw-500 text-muted">
											<em>({{ item.website }})</em>
										</div>
									</template>
									<template #item="{ item }">
										<div class="py-1">
											<div class="fw-600 text-uppercase">{{ item.name }}</div>
											<div class="fw-500 text-muted">{{ item.website }}</div>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label for="cost" class="input-label required">Cost</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<NumberInput
									v-if="false"
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="cost"
									placeholder="Cost"
									v-model="ssl.cost"
									:class="{
										required: !ssl.cost,
									}"
									:rules="[vrules.required(ssl.cost, 'Cost')]"
								>
								</NumberInput>
								<v-text-field
									type="number"
									outlined
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`cost`"
									placeholder="Cost"
									prependInnerIcon="mdi-currency-usd"
									hideTopMargin
									v-model="ssl.cost"
									:class="{
										required: !ssl.cost,
									}"
									:rules="[vrules.required(ssl.cost, 'Cost')]"
									@keypress="limitDecimal($event, ssl.cost)"
								></v-text-field>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label for="sell_price" class="input-label text-right required">Sell Price</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<NumberInput
									v-if="false"
									hide-details
									clearable
									id="sell_price"
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Sell Price"
									v-model="ssl.sales_price"
									:class="{
										required: !ssl.sales_price,
									}"
									:rules="[vrules.required(ssl.sales_price, 'Sell Price')]"
								>
								</NumberInput>

								<v-text-field
									type="number"
									outlined
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`sell-price`"
									placeholder="Sell Price"
									prependInnerIcon="mdi-currency-usd"
									v-model="ssl.sales_price"
									@keypress="limitDecimal($event, ssl.sales_price)"
									:class="{
										required: !ssl.sales_price,
									}"
									:rules="[vrules.required(ssl.sales_price, 'Sell Price')]"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label required">Start Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									v-model="ssl.start_date"
									:class="{
										required: !ssl.start_date,
									}"
									:rules="[vrules.required(ssl.start_date, 'Start Date')]"
									@change="clearEndDate"
								>
								</DatePicker>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label for="end-date" class="input-label required">End Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="end-date"
									placeholder="End Date"
									v-model="ssl.end_date"
									:disablePastFrom="disablePastEndDate"
									:class="{
										required: !ssl.end_date,
									}"
									:rules="[vrules.required(ssl.end_date, 'End Date')]"
									:min-date="ssl.start_date"
								></DatePicker>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="py-0">
								<label class="input-label">Remark </label>
							</v-col>

							<v-col lg="9" class="my-auto py-0">
								<TextAreaInput
									:rows="4"
									dense
									ref="comment"
									hide-details
									v-model="ssl.remark"
									placeholder="Enter Remark..."
									:disabled="pageLoading"
									:loading="pageLoading"
									:word-limit="200"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				:disabled="pageLoading || !formValid"
				tile
				color="blue darken-4"
				class="text-white my-auto mt-3"
				v-on:click="updatessl"
				>Save</v-btn
			>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				class="my-auto mt-3"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { mapGetters } from "vuex";
import { GET, POST } from "@/core/services/store/request.module";
/* import CreateServer from "@/view/pages/leads/create/create-server"; */
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import NumberInput from "@/view/components/NumberInput";
// import MomentJS from "moment-timezone";
//import {  SET_ERROR } from "@/core/services/store/common.module";
// import { HostingEventBus } from "@/core/lib/hosting.lib";
import MainMixin from "@/core/mixins/main.mixin.js";
/* import { toSafeInteger } from "lodash"; */
import { toString } from "lodash";
import objectPath from "object-path";
export default {
	mixins: [MainMixin],
	name: "update-ssl",
	title: "update-ssl",
	props: {
		updatedDialog: {
			type: Boolean,
			default: false,
		},
		detailId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			formValid: false,
			pageLoading: false,
			ssl: {
				service_provider: null,
				cost: null,
				sales_price: null,
				start_date: null,
				end_date: null,
				remark: null,
			},
			backupEndDate: null,
			serverProviders: [],
			disablePastEndDate: null,
		};
	},

	components: {
		Dialog,
		NumberInput,
		TextAreaInput,
		/* CreateServer, */
		DatePicker,
	},
	mounted() {
		// this.getServiceProvider();
		if (this.detailId) {
			this.getssl();
			this.getServiceProviders();
		}
	},
	watch: {
		"ssl.start_date": {
			handler: function (param) {
				this.disablePastEndDate = this.calculate_next_day(param);
			},
		},
	},
	methods: {
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		updatessl() {
			this.pageLoading = true;
			const payload = {
				service_provider: this.ssl.service_provider,
				new_cost: this.ssl.cost,
				sales_price: this.ssl.sales_price,
				end_date: this.ssl.end_date,
				start_date: this.ssl.start_date,
				remark: this.ssl.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(POST, { url: `updatessl/${this.detailId}`, data: { ...payload } })
				.then(() => {
					this.$emit("success");
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSettings(data) {
			this.serverProviders = [...data];
		},
		getssl() {
			this.pageLoading = true;
			// http://127.0.0.1:8000/api/v1/ssl-details/6
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `ssl-details/${this.detailId}` })
				.then((data) => {
					_this.ssl.cost = data.cost;
					_this.ssl.sales_price = data.sales_price;
					_this.ssl.start_date = data.start_date;
					_this.ssl.remark = data.remark;
					_this.ssl.service_provider = data.service_provider;
					_this.backupEndDate = data.end_date;
					setTimeout(() => {
						_this.ssl.end_date = data.end_date;
					}, 100);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getServiceProviders() {
			this.pageLoading = true;
			// https://staging.xxxy2023.com/api/v1/service-provider
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `service-provider` })
				.then((data) => {
					if (data && Object.keys(data).length && Array.isArray(objectPath.get(data, "tbody"))) {
						this.serverProviders = data.tbody.filter((row) => row.is_ssl == 1);
					} else {
						this.serverProviders = [];
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		clearEndDate() {
			this.ssl.end_date = null;
		},
	},

	// computed: {
	// 	...mapGetters(["errors"]),
	// },
};
</script>

<template>
	<Dialog :dialog="updatedDialog" :dialog-width="900" @close="$emit('close')">
		<template v-slot:title>Renew SSL</template>
		<template v-slot:body>
			<v-sheet id="lead">
				<v-form
					ref="extendCheckoutForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="renewSSL"
				>
					<div class="">
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label for="cost" class="input-label required">Cost</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<!-- <NumberInput
									v-if="false"
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="cost"
									placeholder="cost"
									v-model="ssl.cost"
								>
								</NumberInput> -->
								<v-text-field
									clearable
									type="number"
									outlined
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`cost`"
									placeholder="Cost"
									prependInnerIcon="mdi-currency-usd"
									v-model="ssl.cost"
									:class="{
										required: !ssl.cost,
									}"
									:rules="[vrules.required(ssl.cost, 'Cost')]"
									@keypress="limitDecimal($event, ssl.cost)"
									@paste="disablePaste"
								></v-text-field>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label for="sell_price" class="input-label text-right required">Sell Price</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<NumberInput
									v-if="false"
									hide-details
									clearable
									id="sell_price"
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Sell Price"
									v-model="ssl.sales_price"
									:class="{
										required: !ssl.sales_price,
									}"
									:rules="[vrules.required(ssl.sales_price, 'Sell Price')]"
								>
								</NumberInput>

								<v-text-field
									type="number"
									outlined
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									:id="`sell-price`"
									placeholder="Sell Price"
									prependInnerIcon="mdi-currency-usd"
									v-model="ssl.sales_price"
									@keypress="limitDecimal($event, ssl.sales_price)"
									:class="{
										required: !ssl.sales_price,
									}"
									:rules="[vrules.required(ssl.sales_price, 'Sell Price')]"
									@paste="disablePaste"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label required">Start Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									v-model="ssl.start_date"
									:disablePastFrom="disablePastDate"
									:class="{
										required: !ssl.start_date,
									}"
									:rules="[vrules.required(ssl.start_date, 'Start Date')]"
								>
								</DatePicker>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label class="input-label required">End Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="End Date"
									v-model="ssl.end_date"
									:disablePastFrom="disablePastDateEnd"
									:min-date="ssl.start_date"
									:class="{
										required: !ssl.end_date,
									}"
									:rules="[vrules.required(ssl.end_date, 'End Date')]"
								></DatePicker>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="py-0">
								<label class="input-label">Remark </label>
							</v-col>

							<v-col lg="9" class="my-auto py-0">
								<TextAreaInput
									:rows="4"
									dense
									ref="comment"
									hide-details
									v-model="ssl.remark"
									placeholder="Enter Remark..."
									:disabled="pageLoading"
									:loading="pageLoading"
									:word-limit="200"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				:disabled="pageLoading"
				tile
				color="blue darken-4"
				class="text-white my-auto mt-3"
				v-on:click="renewSSL"
				>Save</v-btn
			>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				class="my-auto mt-3"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { mapGetters } from "vuex";
// import CreateServer from "@/view/pages/leads/create/create-server";
import { GET, POST } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import NumberInput from "@/view/components/NumberInput";
// import MomentJS from "moment-timezone";
// import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
// import { HostingEventBus } from "@/core/lib/hosting.lib";
import MainMixin from "@/core/mixins/main.mixin.js";
import { toString } from "lodash";
export default {
	mixins: [MainMixin],
	name: "update-ssl",
	title: "update-ssl",
	props: {
		updatedDialog: {
			type: Boolean,
			default: false,
		},
		detailId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			ssl: {
				cost: null,
				sales_price: null,
				end_Date: null,
				start_date: null,
				remark: null,
			},
			end_date: null,
			serverProvider: [],
			disablePastDate: null,
			disablePastDateEnd: null,
		};
	},

	components: {
		Dialog,
		NumberInput,
		TextAreaInput,
		DatePicker,
		// CreateServer,
	},
	mounted() {
		if (this.detailId) this.getssl();
	},
	watch: {
		"ssl.start_date": {
			handler: function (param) {
				this.disablePastDateEnd = this.calculate_next_day(param);
			},
		},
	},
	methods: {
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		renewSSL() {
			const payload = {
				id: this.detailId,
				cost: this.ssl.cost,
				sales_price: this.ssl.sales_price,
				end_date: this.ssl.end_date,
				start_date: this.ssl.start_date,
				remark: this.ssl.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(POST, { url: `renew-ssl`, data: { ...payload } })
				.then(() => {
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getssl() {
			// http://127.0.0.1:8000/api/v1/ssl-details/6
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `ssl-details/${this.detailId}` })
				.then((data) => {
					let date = _this.calculate_next_day(data.end_date);
					_this.ssl.cost = data.cost;
					_this.ssl.sales_price = data.sales_price;
					// _this.ssl.end_Date = data.end_Date;
					_this.disablePastDate = data.start_date ? date : null;
					_this.ssl.start_date = date ? date : null;
					_this.ssl.remark = data.remark;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},

	// computed: {
	// 	...mapGetters(["errors"]),
	// },
};
</script>
